import React, { useState } from "react";
import PrimaryButton from "../../../../common/primaryButton/PrimaryButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./helper";
import FieldForm from "./accountTypeForm";
import AccountType from "./type";

import "./style.scss";

const SelectAccountType = ({
  setStep,
  productServicesList,
  countryList,
  setFormData,
  formData,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  //temporary state for bancolombia demo
  const [tempChecks, setTempChecks] = useState({
    check1: false,
    check2: false,
    check3: false,
  });
  const [SelectedCountry, setSelectedCountry] = useState(null);

  const onSubmit = (data) => {
    const temp = {
      requestedProducts: data?.requestedProducts?.map((item) => item.value),
      bConductsThirdPartyPayments:
        data?.bConductsThirdPartyPayments === "yes" ? true : false,
      registeredCountryCode: data.registeredCountryCode,
      accountType: "Business",
    };
    setFormData((prev) => ({ ...prev, details: temp }));
    setStep(2);
  };

  return (
    <>
      <form className="cards account-type" onSubmit={handleSubmit(onSubmit)}>
        <AccountType />
        <FieldForm
          errors={errors}
          control={control}
          setError={setError}
          setValue={setValue}
          countryList={countryList}
          productServicesList={productServicesList}
          //temporary state for bancolombia demo
          tempChecks={tempChecks}
          setTempChecks={setTempChecks}
          SelectedCountry={SelectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
        <PrimaryButton
          btnText={"Continue"}
          noBtnIcon
          btnClass="full"
          disabled={
            SelectedCountry === "COL" &&
            (!tempChecks.check1 || !tempChecks.check2 || !tempChecks.check3)
          }
          // click={() => handleClick()}
        />
      </form>
    </>
  );
};

export default SelectAccountType;
