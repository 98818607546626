/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import "./Footer.scss";
import {
  dot,
  feature1,
  feature2,
  logo,
  infinite,
  sideArrow,
  masterCard,
  visa,
  aicpa,
  pci,
} from "../../common/images";
import { NavLink, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../common/ScrollToTop";
import { useInView } from "react-intersection-observer";
import Lottie from "lottie-react";
import animation from "../../assets/animations/footerCta.json";
import SecondaryButton from "../../common/secondaryButton/SecondaryButton";
import { scrollToSection } from "../../common/ScrollToSection";
import { config } from "../../config";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger once when the element comes into view
    threshold: 0.5,
  });
  const navigate = useNavigate();
  const navigateToServices = () => {
    navigate("/services");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const handleClick = useCallback(() => {
    if (isMobile) {
      navigate("/services");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [isMobile]);

  return (
    <footer>
      <div className="container">
        <div className="footer-update">
          <img src={infinite} alt="infinite" />
          <span>Some update to be shown</span>
          <img src={dot} alt="dot" />
        </div>
        <div className="footer-upper-grid">
          <div className="footer-upper-left-info">
            <h3>Get started with Ibanera</h3>
            <p>
              Explore Ibanera, or create an account and start accepting
              payments. <br />
              <br />
              You can contact us to learn how to customize operational flows
              with Ibanera’s API stack.
            </p>
          </div>
          <div className="footer-right-content">
            <div className="footer-upper-center-info">
              <img src={feature1} alt="feature1" />
              <h5>Scalable APIs</h5>
              <p>
                APIs for bank account issuance, <br /> payments, and AML
                compliance.
              </p>
              <NavLink to="/developer" onClick={scrollToTop}>
                View our APIs
                <span>
                  <img src={sideArrow} alt="sideArrow" />
                </span>
              </NavLink>
            </div>
            <div className="footer-upper-right-info">
              <img src={feature2} alt="feature2" />
              <h5>Banking Dashboard</h5>
              <p>Manage services and reporting from your Ibanera portal.</p>
              <NavLink to="/login">
                Login now
                <span>
                  <img src={sideArrow} alt="sideArrow" />
                </span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="footer-cta" ref={ref} onClick={handleClick}>
          <div className="lotify">
            <Lottie
              animationData={animation}
              loop={!inView}
              autoplay={inView}
            />
          </div>
          <div className="footer-cta-content">
            <h3>Let’s build the digital platform of tomorrow</h3>
            <p>
              When it comes to global banking infrastructure, only the best is
              good enough for our sophisticated clients.
            </p>
            <SecondaryButton
              btnIcon={
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.3335 8.20036H12.6668M12.6668 8.20036L8.00016 3.53369M12.6668 8.20036L8.00016 12.867"
                    stroke="#1943dc"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              click={navigateToServices}
              btnText="Browse our services"
            />
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-logo-desc">
            <img src={logo} alt="footer-logo" />
            <p>
              A complete compliance and payments ecosystem from an innovative
              API driven platform for convenient, seamless and user-friendly
              experience.
            </p>
          </div>
          <div className="footer-links-cols">
            <div className="links">
              <h5>Services</h5>
              <NavLink to="/services" onClick={scrollToTop}>
                Merchant Services
              </NavLink>
              <NavLink
                to="/services"
                onClick={() => scrollToSection("accountServices")}
              >
                Account Services
              </NavLink>
              <NavLink
                to="/services"
                onClick={() => scrollToSection("transactionalBanking")}
              >
                Transactional Banking
              </NavLink>
              <NavLink
                to="/services"
                onClick={() => scrollToSection("cardServices")}
              >
                Card Services
              </NavLink>
              <NavLink
                to="/services"
                onClick={() => scrollToSection("currencyExchange")}
              >
                Currency Exchange
              </NavLink>
              {config.DisplayPORTL ? (
                <NavLink
                  to="/services"
                  onClick={() => scrollToSection("portlId")}
                >
                  PORTL
                </NavLink>
              ) : (
                <></>
              )}
              <NavLink
                to="/services"
                onClick={() => scrollToSection("baasServices")}
              >
                API BaaS Services
              </NavLink>
            </div>
            <div className="links">
              <h5>Company</h5>
              <NavLink to="/about" onClick={scrollToTop}>
                About us
              </NavLink>
              <NavLink to="/career" onClick={scrollToTop}>
                Careers
              </NavLink>
              <NavLink to="/partner" onClick={scrollToTop}>
                Become a partner
              </NavLink>
              <NavLink to="/blog" onClick={scrollToTop}>
                News
              </NavLink>
              <NavLink to="/contact" onClick={scrollToTop}>
                Contact
              </NavLink>
            </div>
            <div className="links">
              <h5>Developers</h5>
              <a
                href="https://customer-api-docs.ibanera.com/"
                target="_blank"
                rel="noreferrer"
              >
                Core Banking API
              </a>
              {config.DisplayPORTL ? (
                <a
                  href="https://customer-api-docs.ibanera.com/quickbuy"
                  target="_blank"
                  rel="noreferrer"
                >
                  PORTL API
                </a>
              ) : (
                <></>
              )}
              <a
                href="https://mini-cubes.gitbook.io/monex-apis"
                target="_blank"
                rel="noreferrer"
              >
                Monex API
              </a>
            </div>
            <div className="links">
              <h5>Legal</h5>
              <NavLink to="/legal-agreements" onClick={scrollToTop}>
                Legal Agreements
              </NavLink>
              <NavLink to="/regulatory" onClick={scrollToTop}>
                Regulatory
              </NavLink>
              <NavLink to="/privacy-policy" onClick={scrollToTop}>
                Privacy Policy
              </NavLink>
              <NavLink to="/refund" onClick={scrollToTop}>
                Refund and Error Policy
              </NavLink>
              <NavLink to="/approved-urls" onClick={scrollToTop}>
                Approved URLs
              </NavLink>
              <a
                href="https://ibanera.com/agreements/Terms-Of-Service_NFT_v2.pdf"
                target="blank"
              >
                NFT Terms of Service
              </a>
            </div>
          </div>
        </div>
        <div className="footer-content">
          <div className="text">
            {/* <p>
              Ibanera Private Limited is regulated by the Monetary Authority of
              Singapore and holds a Major Payment Institution license under the
              Payment Service Act .
            </p> */}
            <p>
              Ibanera Services Ltd is a registered MSB with FINTRAC Canada for
              foreign exchange dealing and money transferring.
            </p>
            <p>
              Ibanera LLC is a company registered in Wyoming located at 2120
              Carey Avenue, Cheyenne, WY 82001 and is registered with FinCEN as
              a Money Service Business.
            </p>
            <p>
              Ibanera is a Registered MSP/ISO of Elavon, Inc. Georgia [a wholly
              owned subsidiary of U.S. Bancorp, Minneapolis, MN].
            </p>
          </div>
          <div className="bank-imgs">
            <div className="cert">
              <img src={pci} alt="img" />
              <img src={aicpa} alt="img" />
            </div>
            <div>
              <img src={masterCard} alt="master-card" />
              <img src={visa} alt="visa" />
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <span>
            © {new Date().getFullYear()} Ibanera. All rights reserved.
          </span>
          <ul className="footer-social-icons">
            <li>
              <a target="blank" href="https://twitter.com/IbaneraOfficial">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="twitter-icon"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.9455 23.7544L10.396 15.8445L3.44886 23.7544H0.509766L9.09209 13.9855L0.509766 1.75439H8.05571L13.286 9.20941L19.8393 1.75439H22.7784L14.5943 11.0709L23.4914 23.7544H15.9455ZM19.2185 21.5244H17.2398L4.71811 3.98439H6.6971L11.7121 11.0076L12.5793 12.2263L19.2185 21.5244Z"
                    fill="#98A2B3"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                target="blank"
                href="https://www.linkedin.com/company/ibanera-llc/?viewAsMember=true"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="linkedin-icon"
                >
                  <g clipPath="url(#clip0_2311_18221)">
                    <path
                      d="M22.2234 0.754395H1.77187C0.792187 0.754395 0 1.52783 0 2.48408V23.02C0 23.9763 0.792187 24.7544 1.77187 24.7544H22.2234C23.2031 24.7544 24 23.9763 24 23.0247V2.48408C24 1.52783 23.2031 0.754395 22.2234 0.754395ZM7.12031 21.206H3.55781V9.74971H7.12031V21.206ZM5.33906 8.18877C4.19531 8.18877 3.27188 7.26533 3.27188 6.12627C3.27188 4.98721 4.19531 4.06377 5.33906 4.06377C6.47813 4.06377 7.40156 4.98721 7.40156 6.12627C7.40156 7.26064 6.47813 8.18877 5.33906 8.18877ZM20.4516 21.206H16.8937V15.6372C16.8937 14.3106 16.8703 12.5997 15.0422 12.5997C13.1906 12.5997 12.9094 14.0481 12.9094 15.5435V21.206H9.35625V9.74971H12.7687V11.3153H12.8156C13.2891 10.4153 14.4516 9.46377 16.1813 9.46377C19.7859 9.46377 20.4516 11.8356 20.4516 14.92V21.206V21.206Z"
                      fill="#98A2B3"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2311_18221">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.754395)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </li>
            <li>
              <a
                target="blank"
                href="https://www.facebook.com/people/Ibanera/100068807121314/"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="facebook-icon"
                >
                  <g clipPath="url(#clip0_2311_18222)">
                    <path
                      d="M24 12.7544C24 6.12697 18.6274 0.754395 12 0.754395C5.37258 0.754395 0 6.12697 0 12.7544C0 18.7438 4.3882 23.7084 10.125 24.6086V16.2231H7.07812V12.7544H10.125V10.1106C10.125 7.10315 11.9166 5.44189 14.6576 5.44189C15.9701 5.44189 17.3438 5.67627 17.3438 5.67627V8.62939H15.8306C14.34 8.62939 13.875 9.55447 13.875 10.5044V12.7544H17.2031L16.6711 16.2231H13.875V24.6086C19.6118 23.7084 24 18.7438 24 12.7544Z"
                      fill="#98A2B3"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2311_18222">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.754395)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
